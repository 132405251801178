// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("D:\\Dropbox\\GitHub\\gatsby\\x2lab-gatsby\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-category-template-js": () => import("D:\\Dropbox\\GitHub\\gatsby\\x2lab-gatsby\\src\\templates\\CategoryTemplate.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-support-template-js": () => import("D:\\Dropbox\\GitHub\\gatsby\\x2lab-gatsby\\src\\templates\\SupportTemplate.js" /* webpackChunkName: "component---src-templates-support-template-js" */),
  "component---src-templates-post-template-js": () => import("D:\\Dropbox\\GitHub\\gatsby\\x2lab-gatsby\\src\\templates\\PostTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-page-template-js": () => import("D:\\Dropbox\\GitHub\\gatsby\\x2lab-gatsby\\src\\templates\\PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-pages-404-js": () => import("D:\\Dropbox\\GitHub\\gatsby\\x2lab-gatsby\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("D:\\Dropbox\\GitHub\\gatsby\\x2lab-gatsby\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("D:\\Dropbox\\GitHub\\gatsby\\x2lab-gatsby\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("D:\\Dropbox\\GitHub\\gatsby\\x2lab-gatsby\\src\\pages\\news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-productdetail-js": () => import("D:\\Dropbox\\GitHub\\gatsby\\x2lab-gatsby\\src\\pages\\productdetail.js" /* webpackChunkName: "component---src-pages-productdetail-js" */),
  "component---src-pages-products-js": () => import("D:\\Dropbox\\GitHub\\gatsby\\x2lab-gatsby\\src\\pages\\products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-search-js": () => import("D:\\Dropbox\\GitHub\\gatsby\\x2lab-gatsby\\src\\pages\\search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-secure-js": () => import("D:\\Dropbox\\GitHub\\gatsby\\x2lab-gatsby\\src\\pages\\secure.js" /* webpackChunkName: "component---src-pages-secure-js" */),
  "component---src-pages-support-js": () => import("D:\\Dropbox\\GitHub\\gatsby\\x2lab-gatsby\\src\\pages\\support.js" /* webpackChunkName: "component---src-pages-support-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "D:\\Dropbox\\GitHub\\gatsby\\x2lab-gatsby\\.cache\\data.json")

