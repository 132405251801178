module.exports = [{
      plugin: require('D:/Dropbox/GitHub/gatsby/x2lab-gatsby/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"D:\\Dropbox\\GitHub\\gatsby\\x2lab-gatsby\\src\\layouts\\index.js"},
    },{
      plugin: require('D:/Dropbox/GitHub/gatsby/x2lab-gatsby/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('D:/Dropbox/GitHub/gatsby/x2lab-gatsby/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('D:/Dropbox/GitHub/gatsby/x2lab-gatsby/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('D:/Dropbox/GitHub/gatsby/x2lab-gatsby/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('D:/Dropbox/GitHub/gatsby/x2lab-gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
